export const config={
    app:{
            host:"https://kasap.slagerijwittepaleis.nl",
            port: 3000
        },
    api:{
        host:"https://kasap.slagerijwittepaleis.nl/api",
        port: 3000
    },
    kvk:{
        host:"https://kasap.slagerijwittepaleis.nl/kvk",
        port: 3000
    },
    payments:{
        host:"https://kasap.slagerijwittepaleis.nl/payments",
        port: 3000
    },
    ws:{
        host:"wss://kasap.slagerijwittepaleis.nl/ws",
        port: 3001
    },
    webpack: {
        host: "https://kasap.slagerijwittepaleis.nl:8080",
        port: 8080
    },
    token: "AIzaSyCdYmiOMYeEl-35AJeu5s12jEHbuVofhQU"
    }
